import { NavbarMenu } from '../interfaces';
export const ADMIN_NAV_MENU: NavbarMenu[] = [
  {
    name: 'navigation:manage-member',
    url: '/admin/members',
    icon: 'user',
  },
  {
    name: 'navigation:manage-comment',
    url: '/admin/comment',
    icon: 'edit',
  },
  {
    name: 'navigation:manage-content',
    url: '/admin/content',
    icon: 'form',
  },
  {
    name: 'navigation:manage-voting',
    url: '/admin/voting',
    icon: 'profile',
  },
  {
    name: 'navigation:upload-video',
    url: '/admin/upload-video',
    icon: 'upload',
  },
  {
    name: 'navigation:funding',
    url: '/admin/funding',
    icon: 'project',
  },
  {
    name: 'navigation:event',
    url: '/admin/event',
    icon: 'sound',
  },
  {
    name: 'navigation:search-terms',
    url: '/admin/popular-search-terms',
    icon: 'search',
  },
  {
    name: 'navigation:popular-content-banner-settings',
    url: '/admin/popular-content-banner-settings',
    icon: 'setting',
  },
];
