import axios, { AxiosInstance, AxiosResponse } from "axios";

import Auth from "@utils/helpers/auth";
import { CURRENT_ENV } from "@core/configs/env";
import { RcFile } from "antd/lib/upload";
import { message } from "antd";

interface Axios extends AxiosInstance {
  [key: string]: any;
}

const axiosBase: Axios = axios.create({ baseURL: CURRENT_ENV.API_URL });
axiosBase.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const lang = Auth.getLanguage() === 'ko' ? 'kr' : 'en';
    
    if (token) {
      config.headers["Authorization"] = token;
      config.headers["Accept-Language"] = lang;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosBase.interceptors.response.use(
  (config) => config,
  (error) => {
    console.log(error);

    const { response } = error;
    if (response.data.message) {
      message.error(response.data.message);
    }
    return Promise.reject(error);
  }
);
// const listStatus = [
//   500,
//   404,
//   400,
// ];
const authAxios: Axios = axios.create({ baseURL: CURRENT_ENV.API_URL });
authAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const lang = Auth.getLanguage() === 'ko' ? 'kr' : 'en';


    if (token) {
      config.headers["Authorization"] = token;
      config.headers["Accept-Language"] = lang;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

authAxios.interceptors.response.use(
  (config) => config,
  (error) => {
    const { response } = error;

    if (response && response.status === 401) {
      Auth.clearToken();
      window.location.href = "/";
    }
    if (response?.data?.message) {
      message.error(response.data.message);
    }
    return Promise.reject(error);
  }
);

type CallWithParamsMethod = (
  method: "get" | "delete",
  url: string,
  body?: Record<string, any>,
  options?: Record<string, any>,
  useAuth?: boolean,
  usePrefix?: boolean
) => Promise<any>;
type CallWithBodyMethod = (
  method: "post" | "put" | "patch",
  url: string,
  body?: Record<string, any>,
  options?: Record<string, any>,
  useAuth?: boolean,
  usePrefix?: boolean
) => Promise<any>;
type VerbMethod = (
  url: string,
  body?: Record<string, any>,
  options?: Record<string, any>,
  useAuth?: boolean,
  usePrefix?: boolean,
) => Promise<any>;

class BaseService {
  public constructor(private prefix: string, private useAuth = true) { }

  public callWithParams: CallWithParamsMethod = (
    method,
    url,
    params,
    options,
    useAuth = true,
    usePrefix = true,
  ) => {
    if (useAuth && this.useAuth) {
      return authAxios[method](usePrefix ? `${this.prefix}${url}` : url, {
        params,
        ...options,
      }).then(({ data }: AxiosResponse) => data);
    }

    return axiosBase[method](usePrefix ? `${this.prefix}${url}` : url, {
      params,
      ...options,
    }).then(({ data }: AxiosResponse) => data);
  };

  public callWithBody: CallWithBodyMethod = (
    method,
    url,
    body,
    options,
    useAuth = true,
    usePrefix = true,
  ) => {
    if (useAuth && this.useAuth) {
      return authAxios[method](
        usePrefix ? `${this.prefix}${url}` : url,
        body,
        options,
      ).then(({ data }: AxiosResponse) => data);
    }

    return axiosBase[method](
      usePrefix ? `${this.prefix}${url}` : url,
      body,
      options
    ).then(({ data }: AxiosResponse) => data);
  };

  public get: VerbMethod = (
    url,
    params,
    options,
    useAuth,
    usePrefix = true
  ) => {
    return this.callWithParams("get", url, params, options, useAuth, usePrefix);
  };

  public post: VerbMethod = (url, body, options, useAuth, usePrefix = true) => {
    return this.callWithBody("post", url, body, options, useAuth, usePrefix);
  };

  public put: VerbMethod = (url, body, options, useAuth, usePrefix = true) => {
    return this.callWithBody("put", url, body, options, useAuth, usePrefix);
  };

  public patch: VerbMethod = (
    url,
    body,
    options,
    useAuth,
    usePrefix = true
  ) => {
    return this.callWithBody("patch", url, body, options, useAuth, usePrefix);
  };

  public delete: VerbMethod = (
    url,
    params,
    options,
    useAuth,
    usePrefix = true
  ) => {
    return this.callWithParams(
      "delete",
      url,
      params,
      options,
      useAuth,
      usePrefix
    );
  };

  public acceptImage = async (file: RcFile, urlPresigned: string) => {
    try {
      var config: Object = {
        method: 'put',
        url: urlPresigned,
        headers: {
          'Content-Type': file.type
        },
        data: file
      };
      const res = await axios(config)
      return res;
    } catch (e) {
      console.log(e)
    }
  }

  public uploadImage = async (body: Record<string, any>) => {
    try {
      const resUrlPresigned = await axiosBase.post('/upload-file/', body);
      return resUrlPresigned;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

export default BaseService;
