import {
  Dropdown,
  message,
} from "antd";
import { Layout, Menu } from "antd";
import React, { useContext } from "react";

import Auth from "@utils/helpers/auth";
import AvatarDropdown from "./AvatarDropdown";
import { CURRENT_ENV } from "@core/configs/env";
import { GlobalOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import LocaleContext from "@utils/contexts/Locale";
import authApi from "@modules/Authentication/Login/services/auth";
import { useHistory } from "react-router-dom";
import useTranslate from "@core/hooks/useTranslate";

const { Header } = Layout;

interface Props {
  authenticated: boolean;
}

const HeaderCpn = ({ authenticated }: Props) => {
  const [t] = useTranslate();
  const { setLocaleContext } = useContext(LocaleContext);
  const history = useHistory();
  const changeLocale = (locale: string) => {
    setLocaleContext(locale);
    localStorage.setItem("lang", locale);
  };

  const goToPage = (path: string) => history.push(path);

  const adminLogout = () => {
    authApi["adminLogout"]()
      .then((res) => {
        Auth.clearToken();
        Auth.clearRefreshToken();
        goToPage("/admin/login");
        message.success(t("authentication:logout-success"));
      })
      .catch(() => {
        message.error(t("common:message:error"));
      });
  };

  const handleLanguage = async (lang: string) => {
    const body = { language: lang === "en" ? "english" : "korean" }
    try {
      changeLocale(lang)
      await authApi.changeLanguage(body)
    } catch (error: any) {
      const { response } = error;
      if (response.data.message) {
        message.error(response.data.message);
      }
    }
  }

  const menuLocale = (
    <Menu className="languages">
      <Menu.Item key="en" onClick={() => handleLanguage("en")}>
        <img
          className="logo-languages"
          src={`${CURRENT_ENV.PREFIX_IMG}/images/icons/united-kingdom.svg`}
          alt="logo-english"
        />
        <span>English</span>
      </Menu.Item>
      <Menu.Item key="ko" onClick={() => handleLanguage("ko")}>
        <img
          className="logo-languages"
          src={`${CURRENT_ENV.PREFIX_IMG}/images/icons/south-korea.svg`}
          alt="logo-korea"
        />
        <span>Korean</span>
      </Menu.Item>
    </Menu>
  );
  const renderAdminOrStoreHeader = () => {
    return authenticated ? (
      <Header className="header">
        <Link to="/admin/members">
          <img
            className="login-logo"
            src={`${CURRENT_ENV.PREFIX_IMG}/images/logo.png`}
            alt="logo"
          />
        </Link>
        {/* <Button className="btn-logout" onClick={adminLogout}>
          {t("common:logout")}
        </Button> */}
        <AvatarDropdown menu t={t} adminLogout={adminLogout} />
        <Dropdown className="resource" trigger={["click"]} overlay={menuLocale}>
          <GlobalOutlined />
        </Dropdown>
      </Header>
    ) : null;
  };

  return renderAdminOrStoreHeader();
};

export default React.memo(HeaderCpn);
