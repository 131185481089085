import { User } from "@core/interfaces";

class Auth {
  public static getToken() {
    return localStorage.getItem("token");
  }

  public static setToken(token: string) {
    localStorage.setItem("token", token);
  }

  public static setRefreshToken(refreshToken: string) {
    localStorage.setItem("refreshToken", refreshToken);
  }

  public static clearToken() {
    localStorage.removeItem("token");
    const persist = JSON.parse(localStorage.getItem("persist:root") || "{}");
    localStorage.setItem("persist:root", JSON.stringify(persist));
  }

  public static clearRefreshToken() {
    localStorage.removeItem("refreshToken");
    const persist = JSON.parse(localStorage.getItem("persist:root") || "{}");
    persist.user = "{}";
    persist.upload = "{}";
    localStorage.setItem("persist:root", JSON.stringify(persist));
  }

  public static authenticated() {
    const token = Auth.getToken();

    if (token) {
      return true;
    }

    return false;
  }

  public static getUser() {
    const userInfor: User = JSON.parse(JSON.parse(localStorage.getItem("persist:root") || "{}").user)
    return userInfor
  }

  public static clearRole() {
    localStorage.removeItem("role");
  }

  public static getLanguage() {
    return localStorage.getItem("lang");
  }
}

export default Auth;
