import { Avatar as A } from "antd";
import { UserOutlined } from '@ant-design/icons';

interface Props {
  src?: string;
  base?: boolean;
  shape?: "circle" | "square";
  size?: 'large' | 'small' | 'default' | number;
  style?: Record<string, any>;
}

const Avatar = ({
  src,
  base = false,
  shape = "square",
  style,
  size,
  ...rest
}: Props) => {
  return (
    <A
      shape={shape}
      {...rest}
      style={style}
      size={size}
      src={src }
      icon={src !== "" || src !== null ? <UserOutlined /> : ""}
    />
  );
};

export default Avatar;
