import { REMOVE_EPISODE, REMOVE_SEASON, REMOVE_UPLOAD_DATA, SET_BASIC_INFORMATION, SET_CHARACTER_IMAGE, SET_UPLOAD_IMAGE, SET_UPLOAD_IMAGE_SEASON, SET_UPLOAD_SEASON, SET_UPLOAD_VIDEO } from "@store/actions/upload";

import { AnyAction } from "redux";
import { IUploadVideo } from "@core/interfaces";

const uploadState: IUploadVideo = {
  season: [
    {
      seasonTemplateID: "",
      story_kr: "",
      story_eng: "",
      productInformation: [
        {
          name_kr: "",
          data_kr: "",
          name_eng: "",
          data_eng: "",
        }
      ],
      programParticipants: [
        {
          dataNameCharacter_kr: "",
          dataNameCharacter_eng: "",
          dataNameActor_kr: "",
          dataNameActor_eng: "",
          avatar: {},
        }
      ]
    }
  ],
  imageUploadSeason: {
    character: [],
    episode: [],
    voting: []
  }
} as IUploadVideo;

export default function upload(state = uploadState, action: AnyAction) {
  switch (action.type) {
    case SET_UPLOAD_VIDEO: {
      return action.payload.data
    }
    case SET_UPLOAD_IMAGE: {
      return {
        ...state,
        imageUpload: action.payload.data,
      };
    }
    case SET_BASIC_INFORMATION: {
      return {
        ...state,
        basicInfor: action.payload.data
      }
    }
    case REMOVE_SEASON: {
      let data: any = state;
      let newData = data.season.filter((item: any, index: number) => action.payload !== index)
      return {
        ...state,
        season: newData
      }
    }
    case REMOVE_EPISODE: {
      const { idSeason, idEpisode } = action.payload;
      let data: any = state;
      let newData = data.season[idSeason].streamingChildren.filter((item: any, index: number) => idEpisode !== index)
      data.season[idSeason].streamingChildren = newData
      return {
        ...state,
        season: data.season
      }
    }
    case SET_UPLOAD_SEASON: {
      if (action.payload.data.season) return {
        ...state,
        season: action.payload.data.season
      }
      return {
        ...state,
        season: action.payload.data
      }
    }

    case SET_CHARACTER_IMAGE: {
      const { file, name, seasonID } = action.payload;
      let data: any = state;
      if (!data.season[seasonID].programParticipants[name[0]] || !data.season[seasonID].programParticipants[name[0]].avatar) {
        data.season[seasonID].programParticipants[name[0]] = { ...data.season[seasonID].programParticipants[name[0]], avatar: {} };
        data.season[seasonID].programParticipants[name[0]].avatar = file;
        return {
          ...data
        }
      }

      data.season[seasonID].programParticipants[name[0]].avatar = file;
      return {
        ...data
      }
    }

    case SET_UPLOAD_IMAGE_SEASON: {
      const { data, type } = action.payload
      if (type === 'character') {
        return {
          ...state,
          imageUploadSeason: {
            ...state.imageUploadSeason,
            character: data
          }
        }
      }
      if (type === 'episode') {
        return {
          ...state,
          imageUploadSeason: {
            ...state.imageUploadSeason,
            episode: data
          }
        }
      }
      if (type === 'voting') {
        return {
          ...state,
          imageUploadSeason: {
            ...state.imageUploadSeason,
            voting: data
          }
        }
      }
      return {
        ...state
      }
    }

    case REMOVE_UPLOAD_DATA: {
      return {}
    }

    default:
      return state;
  }
}

