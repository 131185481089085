import { BasicInfor, IUploadVideo, ImageUpload, UploadSeason } from "@core/interfaces"

import { UploadFile } from "antd/lib/upload/interface";

export const CLEAR_USER_INFO = "CLEAR_USER_INFO";
export const SET_UPLOAD_VIDEO = "SET_UPLOAD_VIDEO";
export const SET_UPLOAD_IMAGE = "SET_UPLOAD_IMAGE";
export const SET_UPLOAD_IMAGE_SEASON = "SET_UPLOAD_IMAGE_SEASON";
export const SET_BASIC_INFORMATION = "SET_BASIC_INFORMATION";
export const SET_UPLOAD_SEASON = "SET_UPLOAD_SEASON";
export const REMOVE_UPLOAD_DATA = "REMOVE_UPLOAD_DATA";
export const SET_CHARACTER_IMAGE = "SET_CHARACTER_IMAGE";
export const REMOVE_SEASON = "REMOVE_SEASON";
export const REMOVE_EPISODE = "REMOVE_EPISODE";

export function dispatchSetUploadVideoInfor(data: IUploadVideo) {
  return {
    type: SET_UPLOAD_VIDEO,
    payload: { data },
  };
}

export function dispatchSetImageUploadVideo(data: ImageUpload) {
  return {
    type: SET_UPLOAD_IMAGE,
    payload: { data },
  };
}

export function dispatchSetBasicInfor(data: BasicInfor) {
  return {
    type: SET_BASIC_INFORMATION,
    payload: { data }
  }
}

export function dispatchSetUploadSeason(data: UploadSeason) {
  return {
    type: SET_UPLOAD_SEASON,
    payload: { data }
  }
}

export function dispatchSetImageCharacter(file: UploadFile<any>, name: [], seasonID: number) {
  return {
    type: SET_CHARACTER_IMAGE,
    payload: { file, name, seasonID },
  };
}

export function dispatchRemoveUploadVideo() {
  return {
    type: REMOVE_UPLOAD_DATA,
  };
}

export function dispatchRemoveSeason(id: number) {
  return {
    type: REMOVE_SEASON,
    payload: id
  };
}

export function dispatchRemoveEpisode(idSeason: number, idEpisode: number) {
  return {
    type: REMOVE_EPISODE,
    payload: {
      idSeason,
      idEpisode
    }
  };
}

export function dispatchSetImageUploadVideoSeason(data: any, type: string) {
  return {
    type: SET_UPLOAD_IMAGE_SEASON,
    payload: {
      data,
      type
    }
  };
}