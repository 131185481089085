import { ModuleConfig } from "@core/interfaces";
import { name as comment } from "@modules/Comment/reducers/comment";

const config: ModuleConfig = {
  name: "Comment",
  baseUrl: "/admin/comment",
  routes: [
    {
      path: "/",
      page: "CommentPage",
      title: "Comment Page",
      exact: true,
      reducer: {
        name: comment,
        resource: "comment"
      }
    },
  ],
  requireAuthenticated: true
}

export default config;
