import { ModuleConfig } from "@core/interfaces";
import { name as event_all } from "@modules/Event/reducers/event-all";
import { name as event_finish } from "@modules/Event/reducers/event-finish";
import { name as event_in_process } from "@modules/Event/reducers/event-in-process";
import { name as event_notice } from "@modules/Event/reducers/event-notice";

const config: ModuleConfig = {
  name: "Event",
  baseUrl: "/admin/event",
  routes: [
    {
      path: "/",
      page: "EventPage",
      title: "Event Page",
      exact: true,
      reducer: [
        {
          name: event_all,
          resource: "event-all"
        },
        {
          name: event_in_process,
          resource: "event-in-process"
        },
        {
          name: event_finish,
          resource: "event-finish"
        },
        {
          name: event_notice,
          resource: "event-notice"
        },
      ],
    },
    {
      path: "/create-new-event",
      page: "EventAddPage",
      title: "Event Add Page",
      exact: true,
    },
    {
      path: "/:id",
      page: "EventEditPage",
      title: "Event Edit Page",
      exact: true,
    }
  ],
  requireAuthenticated: true
}

export default config;
