import {
  ValuesAdminLanguage,
  ValuesAdminLoginForm
} from "@modules/Authentication/Login/interfaces";

import BaseService from "@core/class/BaseService";

class AuthService extends BaseService {
  public adminLogin = (body: ValuesAdminLoginForm) => {
    return this.post("admin/login", body);
  };

  public storeManagerLogin = (body: ValuesAdminLoginForm) => {
    return this.post("company/auth/signin", body);
  };

  public adminLogout = () => {
    return this.post("admin/logout");
  };

  public storeManagerLogout = () => {
    return this.post("company/auth/logout");
  };

  public changeLanguage = (body: ValuesAdminLanguage) => {
    return this.patch("admin/change-language",body)
  }

}

export default new AuthService("/", false);
