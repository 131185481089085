import { ModuleConfig } from "@core/interfaces";
import { name as members } from "@modules/Members/reducers/members";

const config: ModuleConfig = {
  name: "Members",
  baseUrl: "",
  routes: [
    {
      path: "/admin/members",
      page: "MembersPage",
      title: "Members Page",
      exact: true,
      reducer: {
        name: members,
        resource: "members"
      }
    },
    {
      path: "/admin/members/:id",
      page: "MemberDetail",
      title: "Member Detail Page",
      exact: true,
      reducer: {
        name: members,
        resource: "members"
      }
    }
  ],
  requireAuthenticated: true
}

export default config;
