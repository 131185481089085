import { ModuleConfig } from "@core/interfaces";
import { name as music_video } from "@modules/Funding/reducers/funding-music-video";
import { name as short_movie } from "@modules/Funding/reducers/funding-short-movie";
import { name as web_drama } from "@modules/Funding/reducers/funding-web-drama";

const config: ModuleConfig = {
  name: "Funding",
  baseUrl: "/admin/funding",
  routes: [
    {
      path: "/",
      page: "FundingPage",
      title: "Funding Page",
      exact: true,
      reducer: [
        {
          name: music_video,
          resource: "funding-music-video"
        },
        {
          name: short_movie,
          resource: "funding-short-movie"
        },
        {
          name: web_drama,
          resource: "funding-web-drama"
        },
      ],
    },
    {
      path: "/create-new-crowdfunding",
      page: "FundingAddPage",
      title: "Create new Crowdfunding",
      exact: true
    },
    {
      path: "/:id",
      page: "FundingEditPage",
      title: "Edit Crowdfunding",
      exact: true
    }
  ],
  requireAuthenticated: true
}

export default config;
