import { ModuleConfig } from "@core/interfaces";
import { name as votingFinish } from "@modules/Voting/reducers/votingFinish";
import { name as votingInProcess } from "@modules/Voting/reducers/votingInProcess";

const config: ModuleConfig = {
  name: "VotingPage",
  baseUrl: "/admin/voting",
  routes: [
    {
      path: "/",
      page: "VotingPage",
      title: "Voting Page",
      exact: true,
      reducer: [
        {
          name: votingInProcess,
          resource: "votingInProcess"
        },
        {
          name: votingFinish,
          resource: "votingFinish"
        }]
    },
    {
      path: "/:seasonID/:streamingID/:streamingEpisodeID/:voteID",
      page: "VotingEditPage",
      title: "Voting Edit Page",
      exact: true,
    },
  ],
  requireAuthenticated: true
}

export default config;
