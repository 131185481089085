const pages = [
  "Authentication/Login",
  "Members",
  "Content",
  "UploadVideo",
  "PopularBanner",
  "Comment",
  "Voting",
  "Funding",
  "SearchTerms",
  "Event"
]

export default pages;
