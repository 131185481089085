import { ModuleConfig } from "@core/interfaces";
import { name as content } from "@modules/Content/reducers/content";
import { name as episode } from "@modules/Content/reducers/episode";
import { name as season } from "@modules/Content/reducers/season";

const config: ModuleConfig = {
  name: "Contents",
  baseUrl: "/admin/content",
  routes: [
    {
      path: "/",
      page: "ContentPage",
      title: "Content Page",
      exact: true,
      reducer: {
        name: content,
        resource: "content"
      }
    },
    {
      path: "/:streamingID/add-season",
      page: "SeasonAddPage",
      title: "Add New Season Page",
      exact: true,
    },
    {
      path: "/:streamingID",
      page: "ContentDetailPage",
      title: "Content Detail Page",
      exact: true,
      reducer: {
        name: season,
        resource: "season"
      },
    },
    {
      path: "/:streamingID/:seasonID/add-episode",
      page: "EpisodeAddPage",
      title: "Add New Episode Page",
      exact: true,
    },
    {
      path: "/:streamingID/:seasonID",
      page: "SeasonManagementPage",
      title: "Season Management Page",
      exact: true,
      reducer: {
        name: episode,
        resource: "episode"
      }
    },
    {
      path: "/:streamingID/:seasonID/:episodeID",
      page: "EpisodeManagementPage",
      title: "Episode Management Page",
      exact: true,
    },
  ],
  requireAuthenticated: true
}

export default config;
