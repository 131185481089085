import HeaderBar from "./HeaderBar";
import React from "react";

interface Props {
  authenticated: boolean;
}

const Header = ({ authenticated }: Props) => {
  return (
    <HeaderBar authenticated={authenticated} />
  );
};

export default React.memo(Header);
