import * as actions from "@store/actions/table";

import { call, put, takeEvery } from "redux-saga/effects";

import { Action } from "@core/interfaces";
import { get_action_table } from "@store/reducers/pattern/table";

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

function* get_table_data_source_worker(
  action: Action<string, actions.GetTableDataSourcePayload>
) {
  const {
    identity,
    api,
    method,
    id,
    id2,
    pageIndex,
    pageSize,
    key,
    keySort,
    sortType,
    sorterRole,
    sorterViewRank
  } = action.payload!;
  yield put({
    type: get_action_table(identity, actions.GET_TABLE_DATA_SOURCE_REQUEST),
  }); // trigger loading

  try {
    const params: any = {
      page: pageIndex,
      limit: pageSize,
    };
    if (key !== "") params.key = key;
    if (keySort !== "") params.keySort = keySort;
    if (sorterRole !== -1) params.userRole = sorterRole;
    if (sorterViewRank !== -1) params.videoRank = sorterViewRank;
    if (sortType !== "") params.sortType = sortType;

    var res: ThenArg<ReturnType<typeof api.getList>>;
    if (id !== "" && id2 !== "") {
      res = yield call(
        api[method],
        params,
        id,
        id2
      );
    }
    else {
      res = yield call(
        api[method],
        params,
        id
      );
    }
    const payload = {
      dataSource: res.data.docs,
      pagination: {
        total: res.data.totalDocs,
        current: res.data.page,
        pageSize,
      },
    };
    yield put({
      type: get_action_table(identity, actions.GET_TABLE_DATA_SOURCE_SUCCESS),
      payload,
    });
  } catch (error) {
    yield put({
      type: get_action_table(identity, actions.GET_TABLE_DATA_SOURCE_FAILURE),
    });
  }
}

export function* get_table_data_source_watcher() {
  yield takeEvery(
    actions.GET_TABLE_DATA_SOURCE_REQUEST,
    get_table_data_source_worker
  );
}
