import { useEffect, useState } from 'react';

import Auth from "@utils/helpers/auth";
import Avatar from "@core/components/Avatar";
import HeaderDropdown from './HeaderDropdown';
import { LogoutOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { TranslateFn } from "@core/hooks/useTranslate";
import { User } from "@core/interfaces";
import { getUserInfor } from "@store/selectors/user";
import { useSelector } from 'react-redux';

export type GlobalHeaderRightProps = {
  menu?: boolean;
  t: TranslateFn;
  adminLogout: () => void;
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu, t, adminLogout }) => {
  const [userInfor, setUserInfor] = useState<User>({} as User)
  const dataUser = useSelector(
    getUserInfor(),
  );

  const menuHeaderDropdown = (
    <Menu className="avatar-dropdown__menu" selectedKeys={[]} onClick={() => { }}>
      {/* {menu && (
        <Menu.Item key="center">
          <UserOutlined />
          {t("common:profile")}
        </Menu.Item>
      )}
      {menu && (
        <Menu.Item key="settings">
          <SettingOutlined />
          {t("common:settings")}
        </Menu.Item>
      )} */}
      {/* {menu && <Menu.Divider />} */}
      <Menu.Item key="logout" onClick={adminLogout}>
        <LogoutOutlined />
        {t("common:logout")}
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (Auth.getToken()) {
      const item = Auth.getUser()
      if (item) {
        setUserInfor(item)
      } 
    }
  }, [])

  return (
    <HeaderDropdown className="right" trigger={["click"]} overlay={menuHeaderDropdown}>
      <span className="header-dropdown__action account">
        <Avatar size="small" shape="circle" src={userInfor?.avatar ?? dataUser.avatar} />
        <span className="name anticon">{userInfor?.userName ?? dataUser.userName ?? "Admin"}</span>
      </span>
    </HeaderDropdown>
  );
}
export default AvatarDropdown;
