import { ModuleConfig } from "@core/interfaces";
import { name as crowdfunding } from "@modules/PopularBanner/reducers/crowdfunding";
import { name as streaming } from "@modules/PopularBanner/reducers/streaming";

const config: ModuleConfig = {
  name: "Members",
  baseUrl: "/admin",
  routes: [
    {
      path: "/popular-content-banner-settings",
      page: "PopularBannerPage",
      title: "Popular Banner Page",
      exact: true,
    },
    {
      path: "/popular-content-banner-settings/edit",
      page: "PopularBannerEditPage",
      title: "Edit Popular Banner Page",
      exact: true,
      reducer: [{
        name: streaming,
        resource: "streaming"
      }, {
        name: crowdfunding,
        resource: "crowdfunding"
      }]
    },
  ],
  requireAuthenticated: true
}

export default config;
