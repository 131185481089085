import { ModuleConfig } from "@core/interfaces";

const config: ModuleConfig = {
  name: "Upload Video",
  baseUrl: "",
  routes: [
    {
      path: "/admin/upload-video",
      page: "UploadVideoPage",
      title: "Upload Video Page",
      exact: true,
    },
  ],
  requireAuthenticated: true
}

export default config;
