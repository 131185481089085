export const GET_TABLE_DATA_SOURCE_REQUEST = "GET_TABLE_DATA_SOURCE_REQUEST";
export const GET_TABLE_DATA_SOURCE_SUCCESS = "GET_TABLE_DATA_SOURCE_SUCCESS";
export const GET_TABLE_DATA_SOURCE_FAILURE = "GET_TABLE_DATA_SOURCE_FAILURE";

interface ApiFunction {
  [key: string]: any;
  getList: (params: Record<string, any>, id?: string, id2?: string) => Promise<{ data: ApiDataTable }>;
  deleteByIds?: (ids: string[]) => Promise<{ deletedIds: string[] }>;
}

export interface ApiDataTable {
  docs?: [],
  limit?: number,
  page?: number,
  totalDocs?: number
}

export interface GetTableDataSourcePayload {
  identity: string;
  api: ApiFunction;
  method: string;
  pageIndex: number;
  pageSize: number;
  keySort: string;
  sorterRole: number;
  sorterViewRank: number;
  key: string;
  defaultFilters: Record<string, any>;
  sortType: string;
  id: string;
  id2: string;
}

export function get_table_data_source(
  identity: string,
  api: ApiFunction,
  method: string,
  pageIndex: number,
  pageSize: number,
  keySort: string,
  key: string,
  sorterRole?: number,
  sorterViewRank?: string,
  sortType?: string,
  id?: string,
  id2?: string,
) {
  return {
    type: GET_TABLE_DATA_SOURCE_REQUEST,
    payload: {
      identity,
      api,
      method,
      pageIndex,
      pageSize,
      keySort,
      id,
      id2,
      key,
      sorterRole,
      sortType,
      sorterViewRank,
    },
  };
}
