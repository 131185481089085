import { ModuleConfig } from "@core/interfaces";

const config: ModuleConfig = {
  name: "SearchTerms",
  baseUrl: "",
  routes: [
    {
      path: "/admin/popular-search-terms",
      page: "SearchTermsPage",
      title: "Search Terms Page",
      exact: true,
    },
  ],
  requireAuthenticated: true
}

export default config;
